import { render, staticRenderFns } from "./MobileRobot.vue?vue&type=template&id=fdd7ef82&scoped=true&"
import script from "./MobileRobot.vue?vue&type=script&lang=js&"
export * from "./MobileRobot.vue?vue&type=script&lang=js&"
import style0 from "./MobileRobot.vue?vue&type=style&index=0&id=fdd7ef82&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fdd7ef82",
  null
  
)

export default component.exports