<template>
  <div class="MobileRobot">
    <div class="imgBox">
      <img src="../../assets/mobile/product/robot.png" class="img" />
    </div>
    <div class="param">
      <div class="title">智能送物机器人</div>
      <div class="tit">相关参数</div>
      <div class="text">
        <div class="left">整机尺寸（长×宽×高）:</div>
        <div class="right">685mm×590mm×1130mm</div>
      </div>
      <div class="text">
        <div class="left">柜子尺寸（长×宽×高）:</div>
        <div class="right">335mm×300mm×180mm（上）</div>
      </div>
      <div class="text">
        <div class="left"></div>
        <div class="right">335mm×300mm×250mm（下）</div>
      </div>
      <div class="text">
        <div class="left">重量:</div>
        <div class="right">65kg</div>
      </div>
      <div class="text">
        <div class="left">屏幕尺寸</div>
        <div class="right"></div>
      </div>
       <div class="text">
        <div class="left">（操作屏&液晶屏）:</div>
        <div class="right">10.1寸&21.5寸</div>
      </div>
      <div class="text">
        <div class="left">分辨率:</div>
        <div class="right">1920×1080</div>
      </div>
      <div class="text">
        <div class="left">导航方式:</div>
        <div class="right">slam导航</div>
      </div>
      <div class="text">
        <div class="left">无线通讯:</div>
        <div class="right">WiFi+4G</div>
      </div>
      <div class="text">
        <div class="left">最大负重</div>
        <div class="right"></div>
      </div>
       <div class="text">
        <div class="left">/最大爬坡度:</div>
        <div class="right">10kg/8度</div>
      </div>
       <div class="text">
        <div class="left">最大过障能力:</div>
        <div class="right">2cm</div>
      </div>
       <div class="text">
        <div class="left">续航时间:</div>
        <div class="right">12h</div>
      </div>
       <div class="text">
        <div class="left">系统:</div>
        <div class="right">Android&ROS</div>
      </div>
       <div class="text">
        <div class="left">平台:</div>
        <div class="right">NVIDIA JETSON</div>
      </div>
       <div class="text">
        <div class="left">电池容量:</div>
        <div class="right">26Ah</div>
      </div>

       <div class="text">
        <div class="left">额定输入电压</div>
        <div class="right"></div>
      </div>
       <div class="text">
        <div class="left">/额定输出电压:</div>
        <div class="right">42V</div>
      </div>
       <div class="text">
        <div class="left">额定功率:</div>
        <div class="right">126W</div>
      </div>
       <div class="text">
        <div class="left">传感器:</div>
        <div class="right">声呐、碰撞等</div>
      </div>
       <div class="text">
        <div class="left">运动速度:</div>
        <div class="right">0-1.5m/s（可设置）</div>
      </div>
       <div class="text">
        <div class="left">最小通过宽度:</div>
        <div class="right">0.9m</div>
      </div>


    </div>
    <div class="paramDe">
      <div
        class="txt"
      >智慧餐厅系统是一个从线上预订、点餐、支付的餐厅解决方案，机器人主要负责在餐厅进行配送工作，将餐食送到餐桌，在餐厅复杂的环境中行走，机器人需要有灵活的避障能力和稳定的行走解决方案。</div>
      <div class="data">
        <img src="../../assets/mobile/dataImg.png" class="dataImg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {}
};
</script>
<style scoped lang="less">
.MobileRobot {
  background-color: #fff;
  .imgBox {
    width: 100%;
    padding-top: 0.55rem;
    padding-bottom: 0.48rem;
    display: flex;
    justify-content: center;
    .img {
      width: 1.97rem;
      height: 3.38rem;
    }
  }
  .param {
    text-align: left;
    margin: 0 0.3rem;
    padding-bottom: 0.29rem;
    border-bottom: 1px solid #333;
    .title {
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: 400;
      color: #ff9000;
      padding-bottom: 0.2rem;
      border-bottom: 1px solid #333;
    }
    .tit {
      margin-top: 0.17rem;
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: bold;
      color: #333333;
      margin-bottom: 0.6rem;
    }
    .text {
      margin-top: 0.36rem;
      display: flex;
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 400;
      color: #333333;
      &:first-of-type {
        margin-top: 0;
      }
      .left {
        width: 2.6rem;
        text-align: right;
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        white-space: nowrap;
      }
      .right {
        margin-left: 0.59rem;
      }
    }
  }
  .paramDe {
    margin-top: 0.3rem;
    padding: 0 0.3rem;
    .txt {
      font-size: 0.24rem;
      font-weight: 400;
      color: #333333;
      line-height: 0.4rem;
      text-align: justify;
    }
    .data {
      width: 6.45rem;
      margin-top: 0.39rem;
      padding-bottom: 0.8rem;
      display: flex;

      .dataImg {
        width: 100%;
        height: 0.18rem;
      }
    }
  }
}
</style>